import debounce from 'lodash/debounce';
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { contentFocusIndicatorFocusedTargetClass } from 'shared/constants.platform';
import { openHighlightGptSubMenu } from 'shared/foreground/cmdPalette';
import foregroundEventEmitter from 'shared/foreground/eventEmitter';
import { globalState, updateState } from 'shared/foreground/models';
import contentFrame, {
  portalGate as portalGateToContentFrame,
} from 'shared/foreground/portalGates/contentFrame/to/reactNativeWebview';
import { getDocument } from 'shared/foreground/stateGetters';
import { useTTSWordBoundariesForVoice } from 'shared/foreground/stateHooks';
import { useIsTtsPlayerStateForThisDocument } from 'shared/foreground/stateHooks/tts';
import { useIsAutoHighlightingEnabled } from 'shared/foreground/stateHooks/useIsAutoHighlightingEnabled';
import useWordBoundaryOffset from 'shared/foreground/stateHooks/useWordBoundaryOffset';
import { deleteHighlight } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/highlight';
import { toggleIsAutoHighlightingEnabled } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/highlighterSettings';
import { setFocusedHighlightId } from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import type { HighlightElement } from 'shared/foreground/types';
import copyTextToClipboard from 'shared/foreground/utils/copyTextToClipboard';
import { useFetchWordBoundariesThrottled } from 'shared/foreground/utils/fetchWordBoundaries';
import forwardRef from 'shared/foreground/utils/forwardRef';
import { splitSerializedRange } from 'shared/foreground/utils/locationSerializer';
import { useReadingProgressTracking } from 'shared/foreground/utils/useReadingProgressTracking';
import useShouldProgressivelyRenderHighlights from 'shared/foreground/utils/useShouldProgressivelyRenderHighlights';
import WebContentFramePuppeteer from 'shared/foreground/WebContentFramePuppeteer';
import {
  AnchorScrollTarget,
  Article,
  BaseDocument,
  Category,
  FirstClassDocument,
  Highlight,
  LenientReadingPosition,
  TextDirection,
} from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { TrackPlayerState } from 'shared/types/tts';
import { isYouTubeUrl } from 'shared/typeValidators';
import { isDesktopApp, os } from 'shared/utils/environment';
import exceptionHandler from 'shared/utils/exceptionHandler.platform';
import getDocumentDomain from 'shared/utils/getDocumentDomain';
import promiseAny from 'shared/utils/promiseAny.platform';
import useDebounce from 'shared/utils/useDebounce';

import { useAppearanceStyles } from '../hooks/appearanceStyles';
import { useKeyboardShortcut } from '../hooks/useKeyboardShortcut';
import useScrollLocation, { getScrollLocation, scrollToPosition } from '../hooks/useScrollLocation';
import { reactLazy } from '../utils/dynamicImport';
import { gptPromptInput } from '../utils/gpt';
import { useShortcutsMap } from '../utils/shortcuts';
import useLocation from '../utils/useLocation';
import ContentFocusIndicator from './ContentFocusIndicator';
import { DocumentFrontMatter } from './DocumentFrontMatter';
import styles from './DocumentTextContent.module.css';
import HighlighterPopovers from './HighlighterPopovers';
import { openSingleParentNotebookView } from './NotebookView/notebookHelpers';
import SaveLinkInAppPopover from './Popovers/SaveLinkInAppPopover';
import ReadingProgressBar from './ReadingProgressBar';
import { ReturnToReadingButton } from './ReturnToReadingButton';
import SanitizedDocumentContent from './SanitizedDocumentContent';

declare let window: WindowWithAPIsMissingFromTypeScript;

function useTtsWordTracking({
  documentId,
  contentContainerId,
  scrollableRootId,
}: {
  contentContainerId: string;
  scrollableRootId: string;
  documentId: FirstClassDocument['id'];
}) {
  const tts = globalState((state) => state.tts);
  const isTtsActiveForThisDocument = useMemo(
    () => Boolean(documentId && documentId === tts?.playingDocId),
    [documentId, tts?.playingDocId],
  );

  useEffect(() => {
    // Unset some variables to be safe (mobile doesn't need to do this because the webview is destroyed)
    contentFrame.onDocumentIdChangedForTts();
  }, [documentId]);

  const ttsWordBoundariesForVoice = useTTSWordBoundariesForVoice(documentId);
  useEffect(() => {
    contentFrame.setTtsWordBoundariesForVoice(ttsWordBoundariesForVoice);
  }, [ttsWordBoundariesForVoice]);

  const wordBoundaryOffset = useWordBoundaryOffset(documentId);

  const ttsPlayerStateForThisDocument = useIsTtsPlayerStateForThisDocument(documentId);
  const isTrackPlayerPlaying = useMemo(
    () => ttsPlayerStateForThisDocument === TrackPlayerState.Playing,
    [ttsPlayerStateForThisDocument],
  );
  const debouncedTrackPlayerPosition = useDebounce(tts?.trackPlayerInfo?.position, 100);

  const isAutoScrollEnabled = useMemo(() => Boolean(tts?.autoScrolling), [tts]);

  useEffect(() => {
    if (!isTtsActiveForThisDocument) {
      contentFrame.hideWordBoundaryIndicator();
      return;
    }

    const position = debouncedTrackPlayerPosition + 0.5 + wordBoundaryOffset / 1000;
    contentFrame.updateWordBoundaryIndicator({
      contentContainerId,
      isAutoScrollEnabled,
      scrollableRootId,
      position,
    });
  }, [
    contentContainerId,
    debouncedTrackPlayerPosition,
    isAutoScrollEnabled,
    isTrackPlayerPlaying,
    isTtsActiveForThisDocument,
    scrollableRootId,
    wordBoundaryOffset,
  ]);

  const fetchWordBoundariesThrottled = useFetchWordBoundariesThrottled();

  useEffect(() => {
    portalGateToContentFrame.on('fetch-word-boundaries', fetchWordBoundariesThrottled);
    return () => {
      portalGateToContentFrame.off('fetch-word-boundaries', fetchWordBoundariesThrottled);
    };
  }, [fetchWordBoundariesThrottled]);
}

const EmbeddedYoutubeDocument = reactLazy(() => import('./DocumentReader/EmbeddedYoutubeDocument'));

async function getFirstHighlightId(): Promise<string | undefined> {
  // Grab the first highlight ID
  return (
    await foregroundEventEmitter.emitAsync('getHighlightIdsInSelector', {
      selector: `.${contentFocusIndicatorFocusedTargetClass}`,
    })
  )
    .find(Boolean) // first truthy result from event handlers
    .find(Boolean); // first highlight ID
}

const getOrCreateFirstHighlightId = async ({
  userInteraction = 'unknown',
  temporary = false,
}: {
  userInteraction?: string;
  temporary?: boolean;
}): Promise<{ didCreate: boolean; highlightId: Highlight['id'] }> => {
  const hasSelection = window.getSelection()?.toString();
  const selector = hasSelection ? undefined : `.${contentFocusIndicatorFocusedTargetClass}`;

  let highlightId = await getFirstHighlightId();
  let didCreate = false;

  if (!highlightId || hasSelection) {
    highlightId = (
      await foregroundEventEmitter.emitAsync('highlight', {
        selector,
        userInteraction,
        temporary,
      })
    ).find(Boolean) as string;
    didCreate = true;
  }

  return { didCreate, highlightId };
};

const getContentFromFirstHighlight = async () => {
  const highlightId = await getFirstHighlightId();
  if (!highlightId) {
    return;
  }
  return (await contentFrame.getKnownHighlights())?.[highlightId]?.content?.trim();
};

export type Props = {
  author?: string;
  category: Category;
  content: string;
  currentScrollPosition?: FirstClassDocument['currentScrollPosition'];
  docId: FirstClassDocument['id'];
  faviconUrl?: BaseDocument['favicon_url'];
  highlights: Highlight[];
  languageCode?: BaseDocument['language'];
  onNewFocusTarget?: (newTarget: HTMLElement | void) => void;
  originUrl?: string;
  publishedDate: FirstClassDocument['published_date'] | undefined;
  readingPercent: number;
  readingPosition?: FirstClassDocument['readingPosition'];
  rssSourceName?: string;
  scrollableAncestorRef: React.MutableRefObject<HTMLElement>;
  scrollPercent: number;
  siteName?: BaseDocument['site_name'];
  sourceSpecificData: FirstClassDocument['source_specific_data'];
  sourceUrl?: string;
  tags: BaseDocument['tags'];
  title: string;
  wordCount: number;
};

const defaultExport = React.memo(
  forwardRef<Props, HTMLDivElement>(function DocumentTextContent(
    {
      author,
      category,
      content,
      currentScrollPosition,
      docId,
      faviconUrl,
      highlights,
      languageCode,
      onNewFocusTarget = () => null,
      originUrl,
      publishedDate,
      readingPosition,
      rssSourceName,
      scrollableAncestorRef,
      siteName,
      sourceSpecificData,
      sourceUrl,
      tags,
      title,
      wordCount,
    },
    elementRef,
  ): JSX.Element {
    const elementId = 'document-text-content';

    const highlightIdToOpenAt = globalState(useCallback((state) => state.highlightIdToOpenAt, []));
    const textDirection = globalState(
      useCallback((state) => state.client.readerSettings.desktop.direction, []),
    );
    const isVideoHeaderShown = globalState(useCallback((state) => state.isVideoHeaderShown, []));
    const highlightToOpenAt = highlightIdToOpenAt
      ? highlights.find(({ id }) => id === highlightIdToOpenAt)
      : null;
    const [highlightElements, setHighlightElements] = useState<HighlightElement[]>([]);
    const [isContentFrameManagerActive, setIsContentFrameManagerActive] = useState(false);
    const isAutoHighlightingEnabled = useIsAutoHighlightingEnabled();
    const [canRenderPopovers, setCanRenderPopovers] = useState(false);
    const [
      failedExtensionOrYouTubeHighlightsHighlightIds,
      setFailedExtensionOrYouTubeHighlightsHighlightIds,
    ] = useState<Highlight['id'][]>([]);
    const shortcutsMap = useShortcutsMap();
    const history = useHistory();

    useTtsWordTracking({
      contentContainerId: elementId,
      documentId: docId,
      scrollableRootId: scrollableAncestorRef.current.id,
    });

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.ToggleNotebookView],
      useCallback(() => {
        if (highlights.length === 0) {
          return;
        }
        openSingleParentNotebookView(history, docId);
      }, [highlights, docId, history]),
    );

    const [element, setElement] = useState<HTMLElement>(elementRef.current);
    useEffect(() => {
      if (!element) {
        return;
      }

      const timeouts = [
        setTimeout(() => setIsContentFrameManagerActive(true), 50),
        setTimeout(() => setCanRenderPopovers(true), 500),
      ];

      return () => {
        for (const id of timeouts) {
          clearTimeout(id);
        }
      };
    }, [element, elementRef]);

    const docIdRef: React.MutableRefObject<Article['id']> = useRef(docId);

    const [shouldHighlightIconsBeHidden, setShouldHighlightIconsBeHidden] = useState(false);

    const setElementRef = useCallback(
      (value: HTMLDivElement) => {
        elementRef.current = value;
        setElement(elementRef.current);
      },
      [elementRef],
    );

    useAppearanceStyles();

    const zenMode = globalState(useCallback((state) => state.zenModeEnabled, []));
    const [latestScrollPosition, setLatestScrollPosition] = useState<LenientReadingPosition>(
      currentScrollPosition ?? { scrollDepth: 0, serializedPosition: null },
    );
    const [isReadingEnabled, setIsReadingEnabled] = useState(false);
    useEffect(() => {
      docIdRef.current = docId;
    }, [docId]);

    useEffect(() => {
      if (!elementRef.current) {
        return;
      }
      // Child nodes do not have class list as property
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (elementRef.current.childNodes.length === 1 && elementRef.current.childNodes[0].classList) {
        // Apply the zen mode style on the child node
        if (zenMode) {
          // Child nodes do not have class list as property
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          elementRef.current.childNodes[0].classList.add(styles.zenMode);
        } else {
          // Child nodes do not have class list as property
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          elementRef.current.childNodes[0].classList.remove(styles.zenMode);
        }
      }
    }, [elementRef, content, zenMode]);

    useEffect(() => {
      // when you click a normal link in a doc, you're supposed to get the link popover
      // but when you click a "View content" link, it should open in a browser tab
      // on desktop this means you have add a special data-open-on-desktop attribute but ONLY to "View content" links

      if (!isDesktopApp) {
        return;
      }
      if (!elementRef.current) {
        return;
      }
      elementRef.current.querySelectorAll('p.rw-outer-content > a').forEach((a) => {
        (a as HTMLAnchorElement).dataset.openOnDesktop = 'true';
      });
    }, [elementRef]);

    const { hash, search } = useLocation();
    const anchorScrollTarget: AnchorScrollTarget | undefined = useMemo(() => {
      if (!isContentFrameManagerActive) {
        return undefined;
      }
      const query = new URLSearchParams(search);
      if (query.get('scroll') !== 'anchor') {
        return undefined;
      }
      const url = query.get('url');
      if (!url) {
        throw new Error('no url search param');
      }
      const anchorText = query.get('anchorText') || '';
      return { url, anchorText };
    }, [search, isContentFrameManagerActive]);

    /*
    Reading position
  */
    const initialScrollLocation = useMemo(() => {
      // Skip if they went directly to this document with a fragment identifier
      if (hash?.length > 1) {
        return;
      }
      return currentScrollPosition;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docId]);

    const [initialScrollOffsetY, setInitialScrollOffsetY] = useState<{ value: number }>({ value: 0 });
    const [contentHeight, setContentHeight] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    // Reset scroll position after docId changes
    useEffect(() => {
      scrollToPosition(scrollableAncestorRef, elementRef, { scrollDepth: 0, serializedPosition: null });
      setInitialScrollOffsetY({ value: 0 });
    }, [docId, scrollableAncestorRef, elementRef]);

    useEffect(() => {
      if (elementRef.current) {
        setContentHeight(elementRef.current.clientHeight);
      }
      if (scrollableAncestorRef.current) {
        setWindowHeight(scrollableAncestorRef.current.clientHeight);
      }
    }, [elementRef, scrollableAncestorRef]);

    useEffect(() => {
      const listener = () => {
        if (elementRef.current) {
          setContentHeight(elementRef.current.clientHeight);
        }
        if (scrollableAncestorRef.current) {
          setWindowHeight(scrollableAncestorRef.current.clientHeight);
        }
      };
      window.addEventListener('resize', listener);
      return () => window.removeEventListener('resize', listener);
    }, [elementRef, scrollableAncestorRef]);

    const customGetReadingPosition = useCallback(async () => {
      const newReadingPosition = await getScrollLocation({
        contentRootRef: elementRef.current,
        scrollableRootRef: scrollableAncestorRef.current,
      });
      return newReadingPosition;
    }, [elementRef, scrollableAncestorRef]);

    const updateSkimmingTimerCallback = useCallback((val: number) => {
      // Empty for now, but will be updated when designed.
    }, []);

    const { updateReadingProgress: updateCurrentScrollPos, isUserReading: isReading } =
      useReadingProgressTracking({
        docId: docIdRef.current,
        wordCount,
        contentHeight,
        windowHeight,
        initialScrollOffset: initialScrollOffsetY,
        transformReadingPosition: customGetReadingPosition,
        wpmThreshold: 400,
        isEnabled: isReadingEnabled,
        updateSkimmingTimer: updateSkimmingTimerCallback,
      });

    useEffect(() => {
      if (!readingPosition) {
        setIsReadingEnabled(true);
      } else {
        setTimeout(() => {
          setIsReadingEnabled(true);
        }, 15000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useScrollLocation({
      contentRootRef: elementRef as React.MutableRefObject<HTMLElement>,
      // Don't go to last position if we're scrolling to a highlight
      initialLocation: highlightIdToOpenAt ? undefined : initialScrollLocation ?? undefined,
      scrollableRootRef: scrollableAncestorRef,
      onFirstScroll: (scrollTop) => {
        if (
          readingPosition?.scrollDepth === currentScrollPosition?.scrollDepth ||
          readingPosition?.serializedPosition === currentScrollPosition?.serializedPosition
        ) {
          setIsReadingEnabled(true);
          // hideSidebars(true, {userInteraction: 'unknown'});
        }
        setInitialScrollOffsetY({ value: scrollTop });
      },
      onScroll: (newScrollPosition, currentOffsetY) => {
        if (!updateCurrentScrollPos) {
          return;
        }

        updateCurrentScrollPos(newScrollPosition, currentOffsetY);
        setLatestScrollPosition(newScrollPosition);
      },
    });

    const returnToReadingPosition = useCallback(() => {
      scrollToPosition(
        scrollableAncestorRef,
        elementRef,
        readingPosition ?? { scrollDepth: 0, serializedPosition: null },
      );
      setTimeout(() => {
        setIsReadingEnabled(true);
        setInitialScrollOffsetY({ value: scrollableAncestorRef.current.scrollTop });
      }, 3000);
    }, [scrollableAncestorRef, elementRef, readingPosition]);

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.PageDown],
      useCallback(() => {
        if (scrollableAncestorRef.current !== null) {
          scrollableAncestorRef.current.focus();
        }
      }, [scrollableAncestorRef]),
    );

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.PageUp],
      useCallback(() => {
        if (scrollableAncestorRef.current !== null) {
          scrollableAncestorRef.current.focus();
        }
      }, [scrollableAncestorRef]),
    );

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.Up],
      useCallback(() => {
        if (scrollableAncestorRef.current !== null) {
          scrollableAncestorRef.current.focus();
        }
      }, [scrollableAncestorRef]),
      {
        description: 'Scroll up',
      },
    );

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.Down],
      useCallback(() => {
        if (scrollableAncestorRef.current !== null) {
          scrollableAncestorRef.current.focus();
        }
      }, [scrollableAncestorRef]),
      {
        description: 'Scroll down',
      },
    );

    // If we're opening at a highlight, focus it / an ancestor of it
    const initialContentFocusIndicatorPosition =
      (highlightToOpenAt?.location && splitSerializedRange(highlightToOpenAt.location)?.start) ||
      initialScrollLocation?.serializedPosition;

    const focusTargetRef = useRef<HTMLElement>();

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.Highlight],
      useCallback(async () => {
        if (window.getSelection()?.toString()) {
          foregroundEventEmitter.emit('highlight', {
            collisonOutcome: 'merge',
            userInteraction: 'keyup',
          });
          return;
        }

        if (!focusTargetRef.current) {
          return;
        }

        // If the existing highlight covers the entire focus indicator, then remove
        // it on the hotkey!
        const highlightContent = await getContentFromFirstHighlight();
        const focusContent = focusTargetRef.current.textContent;

        // Remove Word-Joiner character (used in resize handles)
        const cleanFocusContent = focusContent?.replace(/\u2060/g, '').trim();
        if (highlightContent?.trim() === cleanFocusContent) {
          deleteHighlight((await getFirstHighlightId()) as string, { userInteraction: 'keyup' });
        } else {
          // otherwise, create a new highlight and merge any existing ones
          foregroundEventEmitter.emit('highlight', {
            collisonOutcome: 'merge',
            selector: `.${contentFocusIndicatorFocusedTargetClass}`,
            userInteraction: 'keyup',
          });
        }
      }, [focusTargetRef]),
      {
        description: 'Highlight current section',
      },
    );

    useEffect(() => {
      // Event listener sits here to access the elementRef (the article body) and
      // the focus indicator.
      const gpt = async (highlightId: string) => {
        const highlight = await getDocument<Highlight>(highlightId);
        if (!highlight) {
          throw new Error('no highlight');
        }
        // e.g. highlighting a header grabs content from child headers
        const expandedSelection = await gptPromptInput(highlight, focusTargetRef, elementRef);
        const focusIndicatorContents = focusTargetRef.current?.textContent;

        await setFocusedHighlightId(highlightId);
        await updateState(
          (state) => {
            if (!expandedSelection) {
              return;
            }
            state.gptPrompt = {
              prompt: '',
              selection: highlight.markdown,
              expandedSelection,
              surroundingParagraphContents: focusIndicatorContents || '',
            };
          },
          { eventName: 'cmd-palette-gpt-opening', userInteraction: 'keyup' },
        );

        openHighlightGptSubMenu();
      };
      foregroundEventEmitter.on('gpt:open', gpt);

      return () => {
        foregroundEventEmitter.off('gpt:open', gpt);
      };
    }, [focusTargetRef, elementRef]);

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.Ghostreader],
      useCallback(async (event) => {
        event.preventDefault();
        const { highlightId } = await getOrCreateFirstHighlightId({ userInteraction: 'keyup' });
        foregroundEventEmitter.emit(`annotationPopover-${highlightId}:hide`); // Hide when auto-highlighting
        foregroundEventEmitter.emit('gpt:open', highlightId);
      }, []),
      {
        description: 'Invoke Ghostreader',
      },
    );

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.ViewHighlightInNotebookView],
      useCallback(async () => {
        const highlightId = await getFirstHighlightId();
        if (!highlightId) {
          return;
        }
        foregroundEventEmitter.emit(`annotationPopover-${highlightId}:hide`); // Hide when auto-highlighting
        openSingleParentNotebookView(history, docId, highlightId);
      }, [docId, history]),
      {
        description: 'View in Notebook',
      },
    );

    // Open note field of (existing / new) highlight
    useKeyboardShortcut(
      shortcutsMap[ShortcutId.Note],
      useCallback(async () => {
        if (!focusTargetRef.current) {
          return;
        }
        const { didCreate, highlightId } = await getOrCreateFirstHighlightId({
          userInteraction: 'keyup',
        });
        await promiseAny([
          foregroundEventEmitter
            .emitAsync(`annotationPopover-${highlightId}:is-listening?`)
            .then((results) => {
              if (!results.some(Boolean)) {
                throw new Error('None truthy');
              }
            }),
          foregroundEventEmitter.waitFor(`annotationPopover-${highlightId}:listening`),
        ]);
        foregroundEventEmitter.emit(`annotationPopover-${highlightId}:openHighlightNoteForm`, {
          shouldRemoveHighlightOnCancel: didCreate,
        });
      }, [focusTargetRef]),
      {
        description: 'Add / Edit note on current highlight',
      },
    );

    /*
    It seems hot-keys can't assign to cmd/ctrl+c so we need to listen to all keydown events.
    How this should work: https://linear.app/readwise/issue/RW-6149/support-copying-text-of-highlights-in-reader
  */
    useKeyboardShortcut(
      shortcutsMap[ShortcutId.Wildcard],
      useCallback(
        async (event) => {
          if (document.getElementById('notebook-sidebar-panel')?.contains(document.activeElement)) {
            return;
          }

          const cmdOrCtrlPropertyName = os.name.includes('Mac') ? 'metaKey' : 'ctrlKey';
          if (
            !(
              event.nativeKeyboardEvent &&
              event.nativeKeyboardEvent[cmdOrCtrlPropertyName] &&
              event.nativeKeyboardEvent.key.toLowerCase() === 'c'
            ) ||
            window.getSelection()?.toString() ||
            !focusTargetRef.current
          ) {
            return;
          }

          const text = (await getContentFromFirstHighlight()) ?? focusTargetRef.current.innerText;
          if (!text) {
            return;
          }
          copyTextToClipboard(text);
        },
        [focusTargetRef],
      ),
      {
        description: 'Copy text to clipboard',
        preferredEventTrigger: 'keydown',
      },
    );

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.ToggleAutoHighlighting],
      useCallback(() => toggleIsAutoHighlightingEnabled('keyup'), []),
      {
        description: 'Toggle auto-highlighting',
        preferredEventTrigger: 'keyup',
      },
    );

    useKeyboardShortcut(
      shortcutsMap[ShortcutId.Tags],
      useCallback(async () => {
        const { didCreate, highlightId } = await getOrCreateFirstHighlightId({
          userInteraction: 'keyup',
        });
        await promiseAny([
          foregroundEventEmitter
            .emitAsync(`annotationPopover-${highlightId}:is-listening?`)
            .then((results: (boolean | void)[]) => {
              if (!results.some(Boolean)) {
                throw new Error('None truthy');
              }
            }),
          foregroundEventEmitter.waitFor(`annotationPopover-${highlightId}:listening`),
        ]);
        foregroundEventEmitter.emit(`annotationPopover-${highlightId}:openHighlightTagsForm`, {
          shouldRemoveHighlightOnCancel: didCreate,
        });
      }, []),
      {
        description: 'Add / Edit tags on current highlight',
        preferredEventTrigger: 'keyup',
      },
    );

    const [isAltPressed, setIsAltPressed] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [wasAltPressedWhenLastMouseInteractionEnded, setWasAltPressedWhenLastMouseInteractionEnded] =
      useState(false);
    useEffect(() => {
      const listeners: { [name: string]: (event: KeyboardEvent) => void } = {};
      const on = (name: string, callback: (event: KeyboardEvent) => void) => {
        listeners[name] = callback;
        document.addEventListener(name, callback as EventListener);
      };

      on('keydown', (event) => setIsAltPressed(Boolean(event.altKey)));
      on('keyup', (event) => setIsAltPressed(Boolean(event.altKey)));
      on('mousedown', () => {
        setIsMouseDown(true);
        setWasAltPressedWhenLastMouseInteractionEnded(false);
      });
      on('mouseup', (event) => {
        setIsMouseDown(false);
        setWasAltPressedWhenLastMouseInteractionEnded(event.altKey);
      });

      return () => {
        for (const [name, callback] of Object.entries(listeners)) {
          document.removeEventListener(name, callback as EventListener);
        }
      };
    }, []);

    const readingPercent = readingPosition?.scrollDepth ? readingPosition.scrollDepth * 100 : 0;
    const scrollPercent = latestScrollPosition?.scrollDepth ? latestScrollPosition.scrollDepth * 100 : 0;
    const [sanitizedHtml, setSanitizedHtml] = useState('');

    const [htmlSanitizedCount, setHtmlSanitizedCount] = useState(0);
    const onSanitized = useCallback((html: string) => {
      setSanitizedHtml(html);
      setHtmlSanitizedCount((prev) => prev + 1);
    }, []);

    const onFocusTargetChange = useCallback(
      (newTarget: Element | void) => {
        foregroundEventEmitter.emit('content-focus-indicator:new-focus-target', newTarget);
        onNewFocusTarget(newTarget as HTMLElement | undefined);
      },
      [onNewFocusTarget],
    );

    const contentClasses = useMemo(() => {
      const results = [styles.content];

      if (
        isMouseDown
          ? isAutoHighlightingEnabled
            ? !isAltPressed
            : isAltPressed
          : isAutoHighlightingEnabled
            ? !wasAltPressedWhenLastMouseInteractionEnded
            : wasAltPressedWhenLastMouseInteractionEnded
      ) {
        results.push('document-text-content--auto-highlighting-enabled');
      }
      if (zenMode) {
        results.push(styles.zenMode);
      }
      if (shouldHighlightIconsBeHidden) {
        results.push(styles.contentWithoutHighlightIcons);
      }

      if (sourceUrl && isYouTubeUrl(sourceUrl)) {
        results.push('is-youtube-video');
      }

      if (textDirection === TextDirection.RightToLeft) {
        results.push(styles.rtl);
      }

      if (sourceSpecificData?.epub?.originalStylesEnabled) {
        results.push('epub-original-styles');
      }

      return results;
    }, [
      isMouseDown,
      isAutoHighlightingEnabled,
      isAltPressed,
      wasAltPressedWhenLastMouseInteractionEnded,
      zenMode,
      shouldHighlightIconsBeHidden,
      sourceUrl,
      textDirection,
      sourceSpecificData?.epub?.originalStylesEnabled,
    ]);

    const shouldProgressivelyRenderHighlights = useShouldProgressivelyRenderHighlights(docId);

    const nameOrDomain = getDocumentDomain({ rssSourceName, siteName, originUrl });

    const findInDocumentState = globalState((state) => state.findInDocumentState);
    const [matches, setMatches] = useState<HTMLElement[]>([]);
    const [matchRanges, setMatchRanges] = useState<Range[]>([]);

    const isFindInDocumentEnabled = docId && CSS.highlights;

    const executeSearch = useCallback(
      (query: string) => {
        if (!isFindInDocumentEnabled) {
          return;
        }
        const matchedParents: HTMLElement[] = [];

        if (query === undefined || query.trim() === '') {
          return;
        }
        const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters in a query string to prevent regex issues
        const regex = new RegExp(escapedQuery, 'gi');

        const matchingNodes: Node[] = [];
        const walker = document.createTreeWalker(elementRef.current, NodeFilter.SHOW_TEXT, null);

        // Matches all text nodes that satisfy the regex query. Since text nodes are independent, nesting doesn't cause duplication
        while (walker.nextNode()) {
          const currentNode = walker.currentNode as Text;
          if (currentNode.parentElement?.nodeName === 'STYLE') {
            continue;
          }

          const match = currentNode.nodeValue?.match(regex);
          if (match) {
            matchingNodes.push(walker.currentNode);
          }
        }

        const localMatchRanges: Range[] = [];
        matchingNodes.forEach((currentNode) => {
          if (!currentNode.nodeValue) {
            return;
          }

          const regex = new RegExp(`(${escapedQuery})`, 'gi');
          const newNodeList = currentNode.nodeValue.split(regex);
          let runningLength = 0;

          newNodeList.forEach((part) => {
            if (localMatchRanges.length === 10000) {
              return;
            }
            if (part.toLowerCase() !== query.toLowerCase()) {
              runningLength += part.length;
              return;
            }
            const newMatchRange = new Range();

            newMatchRange.setStart(currentNode, runningLength);
            newMatchRange.setEnd(currentNode, runningLength + part.length);

            localMatchRanges.push(newMatchRange);

            matchedParents.push(currentNode.parentElement as HTMLElement);
            runningLength += part.length;
          });
        });

        setMatches(matchedParents);
        setMatchRanges(localMatchRanges);

        if (localMatchRanges.length > 0) {
          CSS.highlights.set(
            'search-results',
            // rendering more than ~10,000 matches crashes the app due to a stack overflow
            new window.Highlight(...localMatchRanges.slice(0, 10000)),
          );
        }

        updateState(
          (state) => {
            if (!state.findInDocumentState) {
              exceptionHandler.captureException(
                'Trying to update match count without FID state. Handled by resetting state.',
              );
              state.findInDocumentState = {
                query,
                matchCount: 0,
                currentMatchIndex: -1,
              };
            }
            state.findInDocumentState.matchCount = localMatchRanges.length;

            if (localMatchRanges.length > 0) {
              state.findInDocumentState.currentMatchIndex = 0;
            }
          },
          { eventName: 'find-in-document-matchcount-updated-index-reset', userInteraction: 'click' },
        );
      },
      [elementRef, isFindInDocumentEnabled],
    );
    const debouncedExecuteSearch = useMemo(() => debounce(executeSearch, 300), [executeSearch]);

    // Given a query, removes mark from previous results and executes search to update FID state accordingly
    useEffect(() => {
      if (!isFindInDocumentEnabled) {
        return;
      }
      CSS.highlights.clear();
      if (!findInDocumentState?.query) {
        setMatches([]);
        setMatchRanges([]);
        return;
      }

      debouncedExecuteSearch(findInDocumentState.query);
    }, [debouncedExecuteSearch, findInDocumentState?.query, isFindInDocumentEnabled]);

    // Highlights the current match
    useEffect(() => {
      if (findInDocumentState?.currentMatchIndex === undefined || !isFindInDocumentEnabled) {
        return;
      }
      const index = findInDocumentState.currentMatchIndex;
      if (index === undefined || matchRanges.length === 0 || index === -1) {
        return;
      }
      matches[index].scrollIntoView({ block: 'center', inline: 'nearest' });
      const activeSearchResultHighlight = new window.Highlight(matchRanges[index]);
      CSS.highlights.delete('search-results-active');
      CSS.highlights.set('search-results-active', activeSearchResultHighlight);
    }, [matches, findInDocumentState?.currentMatchIndex, matchRanges, isFindInDocumentEnabled]);

    return (
      <div className={`${styles.root}`} id="root">
        <div className={styles.progressBarContainer}>
          <ReadingProgressBar
            progress={readingPercent}
            currentScroll={scrollPercent}
            fakeReadingProgress={isReading}
            large
          />
        </div>
        <Suspense fallback={null}>
          {sourceUrl && isYouTubeUrl(sourceUrl) ? (
            <EmbeddedYoutubeDocument
              docId={docId}
              url={sourceUrl}
              transcriptHtml={sanitizedHtml}
              scrollDepth={readingPosition?.scrollDepth}
              failedExtensionOrYouTubeHighlightsHighlightIds={
                failedExtensionOrYouTubeHighlightsHighlightIds
              }
            />
          ) : (
            <DocumentFrontMatter
              author={author}
              category={category}
              docId={docId}
              failedExtensionOrYouTubeHighlightsHighlightIds={
                failedExtensionOrYouTubeHighlightsHighlightIds
              }
              faviconUrl={faviconUrl}
              languageCode={languageCode}
              nameOrDomain={nameOrDomain}
              publishedOrLastHighlightDate={publishedDate}
              tags={tags}
              title={title}
              wordCount={wordCount}
              url={sourceUrl}
            />
          )}
        </Suspense>
        <SanitizedDocumentContent
          category={category}
          className={contentClasses.join(' ')}
          content={content}
          lang={languageCode}
          id={elementId}
          onSanitized={onSanitized}
          originalEmailView={Boolean(sourceSpecificData?.email?.originalEmailView)}
          ref={setElementRef}
        />
        <SaveLinkInAppPopover
          docId={docId}
          sanitizedHtml={sanitizedHtml}
          contentRef={elementRef}
          focusTargetRef={focusTargetRef}
        />
        <WebContentFramePuppeteer
          contentContainer={element}
          containerNodeSelector={`#${elementId},doesnt-exist[key="${htmlSanitizedCount}"]`}
          docId={docId}
          highlightIdToScrollTo={highlightIdToOpenAt}
          isActive={isContentFrameManagerActive}
          isAutoHighlightingEnabled={isAutoHighlightingEnabled}
          mustUseContentContainerArgument
          onHighlightElementsChanged={setHighlightElements}
          onFailedExtensionOrYouTubeHighlightIdsUpdated={
            setFailedExtensionOrYouTubeHighlightsHighlightIds
          }
          shouldProgressivelyRenderHighlights={shouldProgressivelyRenderHighlights}
          anchorScrollTarget={anchorScrollTarget}
          sourceUrl={sourceUrl || ''}
        />
        <ContentFocusIndicator
          contentRootRef={elementRef as React.MutableRefObject<HTMLDivElement>}
          docId={docIdRef.current}
          expectInitialExternalScroll={
            typeof initialScrollLocation?.scrollDepth === 'number' &&
            initialScrollLocation.scrollDepth > 0
          }
          initialSerializedScrollPosition={initialContentFocusIndicatorPosition}
          onNewFocusTarget={onFocusTargetChange}
          ref={focusTargetRef as React.MutableRefObject<HTMLElement>}
          scrollableAncestorRef={scrollableAncestorRef}
          zenMode={zenMode}
          isYouTubeVideo={isYouTubeUrl(sourceUrl || '')}
          isVideoHeaderShown={isVideoHeaderShown}
        />
        {!isReading && (
          <ReturnToReadingButton
            onClick={returnToReadingPosition}
            currentScrollPos={latestScrollPosition}
            currentReadingPos={readingPosition}
          />
        )}
        {isContentFrameManagerActive && canRenderPopovers && (
          <HighlighterPopovers
            eventEmitter={foregroundEventEmitter}
            highlightElements={highlightElements}
            onPositioningModeUpdated={setShouldHighlightIconsBeHidden}
          />
        )}
      </div>
    );
  }),
);

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
